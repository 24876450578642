import React from "react";
import PropTypes from "prop-types";
import {
  Stack,
  Link,
  Heading,
  OrderedList,
  ListItem,
  Box,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Markup } from "interweave";

const References = (props) => {
  const { references } = props;

  if (!references || references.length === 0) return null;

  const firstCitationRef = references[0].reference
    .split(" ")[0]
    .replace(",", "")
    .replace("<p>", "");

  return (
    <Stack marginBottom="1rem">
      <Link id={firstCitationRef} />
      <Heading size="lg" fontWeight="normal" fontFamily="Noto sans, sans-serif">
        References
      </Heading>
      <OrderedList spacing={3}>
        {references.map((ref, index) => {
          const { reference, doi, pubmedId } = ref;
          const firstAuthor =
            references[index + 1] &&
            references[index + 1].reference
              .split(" ")[0]
              .replace(",", "")
              .replace("<p>", "");
          return (
            <ListItem marginLeft={4} key={`reference-${index}`}>
              <Box fontFamily="Noto serif, serif">
                <Link id={firstAuthor} />
                <Markup content={reference} />
                {pubmedId && (
                  <Link
                    href={`https://www.ncbi.nlm.nih.gov/pubmed/${pubmedId}`}
                    isExternal
                  >
                    PubMed
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                )}
                {doi && (
                  <Link href={`https://doi.org/${doi}`} isExternal>
                    {doi}
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                )}
              </Box>
            </ListItem>
          );
        })}
      </OrderedList>
    </Stack>
  );
};

References.propTypes = {
  references: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string,
      doi: PropTypes.string,
      pubMedId: PropTypes.string,
    })
  ),
};

export default References;
