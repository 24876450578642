import React from "react";
import PropTypes from "prop-types";
import { Stack, Link, Heading, Text } from "@chakra-ui/react";
import { Markup } from "interweave";

const CitedBy = (props) => {
  const { citedBy } = props;

  if (!citedBy || citedBy.length === 0) return null;

  return (
    <Stack marginBottom="1rem">
      <Heading size="md" fontWeight="normal">
        Cited By
      </Heading>
      {citedBy.map((metadata, index) => {
        const { title, url, datePublished, journal } = metadata;

        return (
          <Stack key={`cited-by-${index}`}>
            <Text fontFamily="Noto serif, serif">
              <Link isExternal href={url}>
                <Markup content={title} />
              </Link>
              Article in <strong>{journal}</strong>, published {datePublished}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );
};

CitedBy.propTypes = {
  citedBy: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      datePublished: PropTypes.string,
      journal: PropTypes.string,
    })
  ),
};

export default CitedBy;
